<template>

<div class="account-section-main">

	<h2>My Profile</h2>
	<div class="two-columns">
		<div class="form-pair">
			<label-required label="First Name" :condition="contact.first_name" />
			<input type="text" id="contact-first-name" v-model="contact.first_name" class="required">
		</div>
		<div class="form-pair">
			<label-required label="Last Name" :condition="contact.last_name" />
			<input type="text" id="contact-last-name" v-model="contact.last_name" class="required">
		</div>
		<div class="form-pair">
			<label-required label="Email Address" :condition="contact.email_address" />
			<input type="text" id="contact-email-address" v-model="contact.email_address" class="required">
		</div>
		<div class="form-pair">
			<label>Phone Number</label>
			<input type="text" id="billing-phone-number" v-model="contact.phone_number">
		</div>
	</div>

	<h3>Billing address</h3>
	<div class="form-pair">
		<label-required label="Address Line 1" :condition="contact.address_1" />
		<input type="text" id="billing-address1" v-model="contact.address_1">
	</div>
	<div class="form-pair">
		<label for="billing-address2">Address Line 2</label>
		<input type="text" id="billing-address2" v-model="contact.address_2">
	</div>
	<div class="three-columns">
		<div class="form-pair">
			<label-required label="City" :condition="contact.city" />
			<input type="text" id="billing-city" v-model="contact.city">
		</div>
		<div class="form-pair">
			<label-required label="State" :condition="contact.state" />
			<state-select id="billing-state" v-model="contact.state" :selectedState="contact.state" :showApo="true" :showProvinces="true" placeHolder="State / Province..." :showAbbr="true" />
		</div>
		<div class="form-pair">
			<label-required label="Zip / Postal Code" :condition="contact.zip_code" />
			<input type="text" id="billing-zip" v-model="contact.zip_code" autocomplete="off">
		</div>
	</div>

	<h3>Shipping address</h3>
	<div class="form-pair">
		<label for="shipping-address1">Address Line 1</label>
		<input type="text" id="shipping-address1" v-model="shippingAddress.address_1">
	</div>
	<div class="form-pair">
		<label for="shipping-address2">Address Line 2</label>
		<input type="text" id="shipping-address2" v-model="shippingAddress.address_2">
	</div>
	<div class="three-columns">
		<div class="form-pair">
			<label for="shipping-city">City</label>
			<input type="text" id="shipping-city" v-model="shippingAddress.city">
		</div>
		<div class="form-pair">
			<label for="shipping-state">State</label>
			<state-select id="shipping-state" v-model="shippingAddress.state" :selectedState="shippingAddress.state" :showApo="true" :showProvinces="true" placeHolder="State / Province..." :showAbbr="true" />
		</div>
		<div class="form-pair">
			<label for="shipping-zip">Zip / Postal Code</label>
			<input type="text" id="shipping-zip" v-model="shippingAddress.zip_code" autocomplete="new-password">
		</div>
	</div>
	<div style="margin: 0.5em 0 0 0;">
		<div class="button action" @click="saveContact()">Save Profile</div>
	</div>
	
	<transition name="fade">
		<div class="message account-message" :class="{ alert: error }" v-if="contactMessage">
			<strong>
				<span class="material-symbols-outlined" v-if="error">&#xe645</span>
				<span class="material-symbols-outlined" v-else>&#xe5ca</span>
				<message :message="contactMessage"></message>
			</strong>
		</div>
	</transition>

	
	<div class="form-group column columns columns-2">
		<div>
			<h2>Reset Password</h2>
			<div class="form-pair">
				<label for="new-password">New Password</label>
				<input type="password" id="new-password" v-model="newPassword" autocomplete="new-password">
			</div>
			<div class="form-pair">
				<label for="retype-password">Retype Password</label>
				<input type="password" id="retype-password" v-model="retypeNewPassword" autocomplete="new-password">
			</div>
		</div>
		<div>
			<!-- <h2>Security Question</h2>
			<div class="form-pair">
				<label for="security-question">Security Question</label>
				<select name="security_question_id" id="security-question" v-model="user.security_question_id">
					<option>Security Question...</option>
					<option v-for="securityQuestion in securityQuestions" :value="securityQuestion.security_question_id">{{ securityQuestion.security_question }}</option>
				</select>
			</div>
			<div class="form-pair">
				<label for="security-answer">Answer</label>
				<input type="text" id="security-answer" v-model="user.answer_text">
			</div> -->
		</div>
		
		<div :class="['button', newPassword && retypeNewPassword ? 'action' : 'disabled' ]" @click="saveUser()">Save Password</div>
		
		<transition name="fade">
			<div class="message account-message" :class="{ alert: error }" v-if="userMessage">
				<strong>
					<span class="material-symbols-outlined" v-if="error">&#xe645</span>
					<span class="material-symbols-outlined" v-else>&#xe5ca</span>
					<message :message="userMessage"></message>
				</strong>
			</div>
		</transition>
	</div>

</div>

</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
import LabelRequired from '@/components/LabelRequired.vue'

export default {
	name: 'my-profile',
	components: {
        'state-select': StateSelect,
		'label-required': LabelRequired
    },
	data() {
		return {
			contact: {
				first_name: '',
				last_name: '',
				email_address: '',
				company_name: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				zip_code: '',
				country_code: '',
				phone_number: ''
			},
			error: false,
			contactMessage: null,
			userMessage: null,
			shippingAddresses: [],
			user: {
				user_name: null,
				email_address: null,
				security_question_id: null,
				answer_text: null
			},
			newPassword: null,
			retypeNewPassword: null,
			securityQuestions: []
		}
	},
	created() {
		this.fetchData()
	},
	watch: {
		userMessage: function(value) {
            if (value) {
                setTimeout(() => {
					this.userMessage = null
				}, 3000)
            }
        },
		contactMessage: function(value) {
            if (value) {
                setTimeout(() => {
					this.contactMessage = null
				}, 3000)
            }
        }
	},
	computed: {
		shippingAddress: {
			get() {
				return this.shippingAddresses[0] || {
					first_name: '',
					last_name: '',
					email_address: '',
					company_name: '',
					address_1: '',
					address_2: '',
					city: '',
					state: '',
					zip_code: '',
					country_code: '',
					phone_number: ''
				}
			},
			set(value) {}
		}
	},
	methods: {
		fetchData() {
			this.apiCall({ destination: 'get_item_user', data: { user_id: this.user.user_id } }).then(obj => {
				if (obj.status == 'success') {
					this.user = obj.user_detail
					this.apiCall({ destination: 'get_item_contact', data: { contact_id: this.user.contact_id } }).then(obj => {
						this.contact = Object.keys(obj.contact).length > 0 ? obj.contact : {
							address_id: '',
							first_name: '',
							last_name: '',
							email_address: '',
							company_name: '',
							address_1: '',
							address_2: '',
							city: '',
							state: '',
							zip_code: '',
							country_code: '',
							phone_number: ''
						}
		            }).catch(error => {
		                console.log(error)
		            })
					this.apiCall({ destination: 'get_list_shipping_addresses', data: { contact_id: this.user.contact_id } }).then(obj => {
						this.shippingAddresses = obj.shipping_addresses
		            }).catch(error => {
		                console.log(error)
		            })
				} else {
					this.updateLoginRequired(true)
					this.$router.push('/myaccount/login')
				}
            }).catch(error => {
                console.log(error)
            })
		},
		saveUser() {
			if (!this.newPassword || !this.retypeNewPassword) { return }
			this.error = false
			this.userMessage = null
			if (this.newPassword && (this.newPassword != this.retypeNewPassword)) {
				this.error = true
				this.userMessage = "Passwords do not match."
				return
			}
			var data = {
				user_type_code: 'CUSTOMER',
				contact_id: this.contact.contact_id,
				user_id: this.user.user_id,
				user_name: this.user.user_name,
				new_password: this.newPassword
			}
			this.apiCall({ destination: 'save_item_user', data: data }).then(obj => {
				if (obj.status == 'error') {
					this.userMessage = obj.message
				} else {
					this.userMessage = 'Your password has been reset.'
				}
            }).catch(error => {
                console.log(error)
            })
		},
		saveContact() {
			this.error = false
			this.contactMessage = null
			var requiredFields = [ 'first_name', 'last_name', 'address_1', 'city', 'state', 'zip_code', 'email_address' ]
			requiredFields.forEach(key => {
				if (!this.contact[key]) {
					this.contactMessage = this.$caplitalizeWords(key) + ' is required.'
					this.error = true
				}
			})
			if (this.error) {
				return
			}
			this.apiCall({ destination: 'save_item_contact', data: this.contact }).then(obj => {
				if (obj.status == 'success') {
					this.shippingAddress.contact_id = this.user.contact_id
					this.apiCall({ destination: 'save_item_shipping_address', data: this.shippingAddress }).then(obj => {
						if (obj.status == 'success') {
							this.shippingAddress.address_id = obj.address_id
							this.contactMessage = "Your profile has been updated."
						} else {
							this.contactMessage = obj.message
						}
					}).catch(error => {
						console.log(error)
					})
				} else {
					this.contactMessage = obj.message
					this.error = true
				}
            }).catch(error => {
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss" scoped>

.form-group {
	grid-gap: 0.25em 1em;
	align-items: end;
}

.form-pair {
	display: grid;
	grid-template-columns: 1fr;
	label {
		font-size: .9em;
   		color: #282c2e;
   		margin: 0 0 0.25em 0;
	}
	input, select {
		font-size: 1.1em;
		margin: 0 0 0.5em 0;
		padding: 6px 10px;
		background-color: #fff;
		border: 1px solid #d1d1d1;
		border-radius: 3px;
	}

}
@media (max-width: 1200px) {
	.form-group, .columns, .columns-2 {
		grid-template-columns: 100%;
		h2 { grid-column: span 1; }
		select { width: 100%; }
		input { width: 93.5%; }
	}
}
</style>